<template>
  <div>
    <c-table
      title=""
      :columns="checkGrid.columns"
      :data="checkGrid.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      isDense
      :isTitle="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th1-style"
            :rowspan="1" :colspan="4">
            <span>
              상태값 정보
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="text-light-blue" >
              <q-chip color="light-blue" text-color="white">
                적용가능
              </q-chip>
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
            <div class="text-light-blue" >
              구성물질이 가지는 규제정보를 기준에 만족하는 경우, 여러 구성물질 중 모든 기준에 만족하는 경우를 일컬음
            </div>
          </q-td>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="text-grey" >
              <q-chip color="grey" text-color="white">
                대기
              </q-chip>
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
            <div class="text-grey" >
              구성물질이 가지는 규제정보의 기준이 정해져 있지 않은 경우
              ,summary해서 적용한다고 알려주되, 선택할 수 있게 처리
              ,모든 구성물질이 규제정보 기준이 정해지지 않은 경우
            </div>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="text-green" >
              <q-chip color="green" text-color="white">
                적용가능
              </q-chip>
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
            <div class="text-green" >
              구성물질이 가지는 규제정보를 기준에 만족하는 경우, 하지만 여러 구성물질 중 단 하나라도 기준에 만족하지 않는 경우가 포함된 것을 일컬음
            </div>
          </q-td>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="text-orange" >
              <q-chip color="orange" text-color="white">
                대기
              </q-chip>
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
            <div class="text-orange" >
              구성물질이 가지는 규제정보의 기준이 정해져 있지 않은 경우
              ,summary해서 적용한다고 알려주되, 선택할 수 있게 처리
              ,여러 구성물질 중 하나 이상이 규제정보 기준이 정해지지 않았으며 그 외의 경우는 적용불가인 경우
            </div>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="text-red" >
              <q-chip color="red" text-color="white">
                적용불가
              </q-chip>
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
            <div class="text-red" >
              구성물질이 가지는 규제정보를 기준에 만족하지 않는 경우
            </div>
          </q-td>
          <q-td
            class="text-center th-td-style td1-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-left th-td-style td2-style"
            :rowspan="1" :colspan="1">
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <c-table
      ref="table-result"
      title="검증결과"
      :columns="gridResult.columns"
      :data="gridResult.data"
      :merge="gridResult.merge"
      :gridHeight="gridHeight"
      :usePaging="false"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
    >
    
      <!-- 
        해당 테이블에서 체크하여 처리하는 것이 아닌 TO-BE를 통해 체크하여 처리
        selection="multiple"
        rowKey="regulatedItemCd" 
      -->
      <!-- 
        검증에 대한 값을 그대로 받는게 아닌 수정해서 적용할 수 있게 처리
        checkDisableColumn="statusFlag" 
      -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn
            v-show="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="PUT"
            label="LBLAPPLY"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='statusFin'">
          <template v-if="props.row.statusFin==='Y1'">
            <q-chip color="light-blue" text-color="white">
              적용가능
            </q-chip>
          </template>
          <template v-else-if="props.row.statusFin==='Y2'">
            <q-chip color="green" text-color="white">
              적용가능
            </q-chip>
          </template>
          <template v-else-if="props.row.statusFin==='N'">
            <q-chip color="red" text-color="white">
              적용불가
            </q-chip>
          </template>
          <template v-else-if="props.row.statusFin==='W1'">
            <q-chip color="grey" text-color="white">
              대기
            </q-chip>
          </template>
          <template v-else-if="props.row.statusFin==='W2'">
            <q-chip color="orange" text-color="white">
              대기
            </q-chip>
          </template>
        </template>
      </template>
    </c-table>
    <c-table
      title=""
      :columns="regulGrid.columns"
      :data="regulGrid.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      isDense
      :isTitle="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th2-style"
            :rowspan="1" :colspan="1">
            <span>
              AS-IS
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th2-style"
            :rowspan="1" :colspan="1">
            <span>
              TO-BE
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <q-td
            class="text-left th-td-style td1-style"
            :rowspan="1" :colspan="1">
            <div class="divchklaw" v-for="(item, idx) in asis.bills" :key="`asis-bill${idx}`">
              <div class="lawspan">{{item.regulationBillName}}</div>
              <q-checkbox
                v-for="(regul, _idx) in item.reguls" :key="`asis-regul${_idx}`"
                class="customqcbox"
                dense
                color="orange-custom"
                true-value="Y"
                false-value="N"
                :disable="true"
                :label="regul.regulatedItemName"
                v-model="regul.val"
              />
            </div>
          </q-td>
          <q-td
            class="text-left th-td-style td3-style"
            :rowspan="1" :colspan="1">
            <div class="divchklaw" v-for="(item, idx) in tobe.bills" :key="`tobe-bill${idx}`">
              <div class="lawspan">{{item.regulationBillName}}</div>
              <!-- PRTR 그룹 표시 X -->
              <template v-for="(regul, _idx) in item.reguls">
                <q-checkbox
                  v-if="regul.regulatedItemCd !== 'D10010007' && regul.regulatedItemCd !== 'D10010008'"
                  :key="`tobe-regul${_idx}`"
                  class="customqcbox"
                  dense
                  color="orange-custom"
                  true-value="Y"
                  false-value="N"
                  :disable="!editable"
                  :label="regul.regulatedItemName"
                  v-model="regul.val"
                />
              </template>
            </div>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <!-- <c-card title="법규별 규제사항" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-6 divchklaw" v-for="(item, idx) in asis.bills" :key="`asis-bill${idx}`">
          <div class="lawspan">{{item.regulationBillName}}</div>
          <q-checkbox
            v-for="(regul, _idx) in item.reguls" :key="`asis-regul${_idx}`"
            class="customqcbox"
            dense
            color="orange-custom"
            true-value="Y"
            false-value="N"
            :disable="true"
            :label="regul.regulatedItemName"
            v-model="regul.val"
          />
        </div>
        <div class="col-6 divchklaw" v-for="(item, idx) in tobe.bills" :key="`tobe-bill${idx}`">
          <div class="lawspan">{{item.regulationBillName}}</div>
          <q-checkbox
            v-for="(regul, _idx) in item.reguls" :key="`tobe-regul${_idx}`"
            class="customqcbox"
            dense
            color="orange-custom"
            true-value="Y"
            false-value="N"
            :disable="true"
            :label="regul.regulatedItemName"
            v-model="regul.val"
          />
        </div>
      </template>
    </c-card> -->
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemVerification',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveData: {
        mdmChemMaterialId: '',
        reguls: [],
      },
      asis: {
        reguls: [],
        bills: [],
      },
      tobe: {
        reguls: [],
        bills: [],
      },
      checkGrid: {
        columns: [
          { name: '1', field: '1', },
          { name: '2', field: '2', },
          { name: '3', field: '3', },
          { name: '4', field: '4', },
        ],
        data: [{}, {}, {}, {}],
      },
      regulGrid: {
        columns: [
          { name: '1', field: '1', },
          { name: '2', field: '2', },
        ],
        data: [{}, {}],
      },
      gridResult: {
        merge: [
          { index: 0, colName: 'regulatedItemCd' },
          { index: 5, colName: 'regulatedItemCd' },
        ],
        columns: [
          {
            name: 'billRegulName',
            field: 'billRegulName',
            label: '법규 - 규제',
            align: 'center',
            sortable: true,
            style: 'width: 30%',
          },
          {
            name: 'chemName',
            field: 'chemName',
            label: '화학물질(CAS No.)',
            align: 'center',
            sortable: true,
            style: 'width: 30%',
          },
          {
            name: 'quantity',
            field: 'quantity',
            label: '정량값',
            align: 'right',
            sortable: true,
            style: 'width: 15%',
            type: 'cost',
          },
          {
            name: 'appliStandSign',
            field: 'appliStandSign',
            label: '부등호',
            align: 'center',
            sortable: true,
            style: 'width: 10%',
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량 대표값(%)',
            align: 'center',
            sortable: true,
            style: 'width: 15%',
          },
          {
            name: 'statusFin',
            field: 'statusFin',
            label: '상태',
            align: 'center',
            sortable: true,
            style: 'width: 10%',
            type: 'custom'
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      verifiUrl: '',
      listUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 300) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.verifiUrl = selectConfig.mdm.mam.chem.materialRegul.verification.url;
      this.listUrl = selectConfig.mdm.mam.chem.materialRegul.list.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.materialRegul.save.url;
      // code setting
      // list setting
      this.getVerifi();
    },
    getVerifi() {
      this.$http.url = this.verifiUrl
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.popupParam.mdmChemMaterialId
      };
      this.$http.request((_result) => {
        this.gridResult.data = _result.data
        // this.$refs['table-result'].selected = this.$_.filter(this.gridResult.data, { statusCheck: true })
        this.getReguls();
      },);
    },
    getReguls() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.popupParam.mdmChemMaterialId
      };
      this.$http.request((_result) => {
        this.asis.reguls = _result.data;
        this.tobe.reguls = this.$_.map(this.$_.cloneDeep(_result.data), (item) => {
          return this.$_.assign(item, {
            val: this.$_.findIndex(this.gridResult.data, regulResult => {
              return regulResult.regulatedItemCd === item.regulatedItemCd
                && regulResult.statusFin !== 'N'
            }) > -1 ? 'Y' : 'N'
          })
        });
        this.asis.bills = [];
        this.tobe.bills = [];
        if (this.asis.reguls && this.asis.reguls.length > 0) {
          this.asis.bills = this.$_.map(this.asis.reguls, (value) => {
            return {
              regulationBillCd: value['regulationBillCd'],
              regulationBillName: value['regulationBillName'],
              reguls: [],
            }
          });
          this.asis.bills = this.$_.uniqBy(this.asis.bills, (item) => {
            return item.regulationBillCd
          })
          this.tobe.bills = this.$_.sortBy(this.$_.cloneDeep(this.asis.bills), ['sortOrder', 'regulatedItemCd'])
          this.$_.forEach(this.asis.bills, bill => {
            this.$set(bill, 'reguls', this.$_.filter(this.asis.reguls, { regulationBillCd: bill.regulationBillCd }))
          })
          this.$_.forEach(this.tobe.bills, bill => {
            this.$set(bill, 'reguls', this.$_.filter(this.tobe.reguls, { regulationBillCd: bill.regulationBillCd }))
          })
        }
      },);
    },
    saveInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '적용하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$set(this.saveData, 'mdmChemMaterialId', this.popupParam.mdmChemMaterialId)
          this.$set(this.saveData, 'reguls', this.$_.filter(this.tobe.reguls, { val: 'Y' }))

          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      Object.assign(this.$data.saveData, this.$options.data().saveData);
      this.$emit('closePopup');
    },
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th1-style
  background: #EEEEEE !important
  font-weight: 700
  width: 100%

.th2-style
  background: #EEEEEE !important
  font-weight: 700
  width: 50%

.td1-style
  width: 8%
.td2-style
  width: 42%
.td3-style
  width: 50%
</style>